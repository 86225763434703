<template>
  <section class="info-tiles">
    <div class="columns no-margin is-mobile has-text-centered">
        <div class="column no-margin is-half">
          <div class="tile is-ancestor">
            <card-info
              :info="info.jml_kegiatan"
              subtitle="Kegiatan Harian"
              targetName="kegiatan-mahasiswa-list"
              appName="aktivitas"
            ></card-info>
            <card-info
              :info="info.jml_ujian"
              subtitle="Ujian"
              targetName="ujian-list"
              appName="ujian"
            ></card-info>
          </div>
        </div>

        <div class="column no-margin is-half">
          <div class="tile is-ancestor">
            <card-info
              :info="info.jml_aktivitas"
              subtitle="Kasus"
              targetName="aktivitas-list"
              appName="aktivitas"
            ></card-info>
            <a v-if="isPSPD" class="empty-card tile is-parent"></a>
            <card-info
              v-else
              :info="info.jml_penilaian"
              subtitle="Penilaian"
              targetName="pencapaian-nilai"
              appName="penilaian"
            ></card-info>
          </div>
        </div>
      </div>
    <div v-if="loading" class="card-content events-card">
      <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
    </div>
  </section>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import onlineCheckMixin from "@/apps/core/mixins/onlineCheckMixin";
import fetchActivatedMixin from "@/apps/core/mixins/fetchActivatedMixin";
import Dashboard from "../models/dashboard.js";

export default {
  name: "Dashboard",
  components: {
    CardInfo: () => import("../components/CardInfo"),
  },
  data() {
    this.dashboardMdl = new Dashboard();
    return this.dashboardMdl.getObservables();
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", [
      "isAdmin",
      "isPreceptor",
      "isPSPD",
    ]),
    isDashboardDataReady() {
      return this.info != null;
    },
    keterampilanListUrl() {
      return this.isAdmin ? "aktivitas-jejaring" : "aktivitas-keterampilan-list";
    },
    subTitleKeterampilan() {
      return this.isAdmin ? "Keterampilan Klinis Jejaring" : "Keterampilan Klinis";
    },
  },
  methods: {
    fetchData() {
      this.dashboardMdl.load("dashboard");
    },
  },
  mixins: [onlineCheckMixin, fetchActivatedMixin],
};
</script>


<style scoped lang="scss">
div.container {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0.5rem !important;
}

.empty-card {
  padding: 0rem;
}

::v-deep .box-notif-pspd article.box {
  background-color: #f1f1f1;
}

::v-deep .box-notif-pspd {
  padding: 0px 10px 0px 10px;
}

::v-deep .box-notif-pspd:first-of-type {
  padding-top: 10px;
}

::v-deep .box-notif-pspd:last-of-type {
  padding-bottom: 10px;
}

.stase-date {
  padding: 0.75rem;
}

.is-black {
  color: #000000 !important;
}

.card-header-title {
  font-weight: 500;
}

hr {
  border: none;
  border-top: 1px solid;
  color: #dbdbdb;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
